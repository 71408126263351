/**************************************
******************My CODE**************
***************************************/




	if (Cookies.set('js-cookie') === undefined) {

		$('body').append('<div class="cookie bg-gray-darker crafted crafted-gray-darker js-cookie-banner" style=""><div class="col-11 cookie-text"><div>En poursuivant votre navigation sur ce site, vous acceptez une utilisation de cookies conformément à notre politique de confidentialité.<a href="/confidentialite" class="c-black-text"><b> En savoir <span style="white-space: nowrap;">plus ?</span></b></a></div></div><div class="col-1 display-inline-flex"><div class="float-time-right"><a href="javascript:void(0);" class="c-black js-cookie">&times;</a></div></div></div>')
	
		$('.js-cookie').click(function(e) {
			$('.js-cookie-banner').fadeOut()
			e.preventDefault()
			Cookies.set('js-cookie', 'veiewed', { expires: 365 })
		})
	} 

